import axiosInstance from '../../passage';
//import HOME_DATA from '../../redux/home/home.data';

export async function fetchData(inputValue) {
    const result = await axiosInstance({
        url: '/api/vehicle/travel/',
        mode: 'no-cors',
        method: 'POST',
        dataType: 'jsonp',
        crossdomain: true,
        cors: true,
        headers: {
            'Access-Control-Allow-Origin' : '*',
            "ccess-Control-Allow-Methods": '*',
            'Access-Control-Allow-Headers': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            'Content-Type': 'application/json',
            'Access-token': `${process.env.REACT_APP_ET_TOKEN}`,
        },
        // credentials: 'include',
        data: {
            "travelWay": "FOCA",
            "licensePlate": inputValue
        }              
    }).then(response => {
        return response.data;

    }).catch(error => {
        console.log("Error: ", error);
        return [];
    });

    return result;
    //return HOME_DATA;
};

export async function checkStatus(inputValue) {
    const finalResults = await fetchData(inputValue);
    
    if(finalResults != null) {
        return finalResults
    } else {
        return {}
    }

    // const finalResults = await fetchData(inputValue);
    
    // const existingHomeData = finalResults.find(homeStatus =>
    //     homeStatus.licensePlate === inputValue 
    // );

    // if(existingHomeData) {
    //     const properties = existingHomeData.properties.find(name =>
    //         name.name == 'BorderFRStatus'
    //     );
    //     return properties;
    // } else {
    //     return [];
    // }
};