import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import Header from './component/header/header.component';
import Spinner from './component/spinner/spinner.component';
import ErrorBoundary from './component/error-boundary/error-boundary.component';

const HomePage = lazy(() => import('./pages/home/home.component'));
const StatusPage = lazy(() => import('./pages/status/status.component'));

function App() {
  return (
  <div className='etf-container'>
    <Header />
    <Switch>
      <ErrorBoundary>
        <Suspense fallback={ <Spinner hidesection={ true } timervalue='0' /> }>
          <Route exact path='/' component={ HomePage } />
          <Route exact path='/status' component={ StatusPage } />
        </Suspense>
      </ErrorBoundary>
    </Switch>
  </div>
  );
}

export default App;
