import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import SpinnerImage from '../../assets/images/spinner.png';

import './spinner.styles.css';

const Spinner = ({ hidesection, timervalue }) => {
    return(       
    <Container>
        <Row>
            <Col className='spinner-overlay section-center mb-5' xs='12' sm='12' md='7' lg='5'>
                {
                    hidesection === false ?
                        <Col sm='12' className={ `spinner-top ${ hidesection }` }>
                            <h3 className='mb-4'>My custom status</h3>
                            <p>Please stay here!</p>
                        </Col>
                    :
                    null
                }

                {
                    timervalue <= 10 ?
                    <div className='spinner-conteiner'>
                        <img src={ SpinnerImage } alt='Spinner' />
                    </div>
                    :
                    <h3 className='text-center'>The vehicle was not found. Please go to the previous step and try again.</h3>
                }

                {
                    hidesection === false ?
                        <Col sm='12' className='spinner-bottom'>
                            <Link className='white-text' to='/'>
                                <FontAwesome className='arrow-left' name='arrow-left' />
                                <span className='prev-step'>Previous step</span>
                            </Link>
                        </Col>
                    :
                    null
                }
            </Col>
        </Row>
    </Container>
    );
};

export default Spinner;